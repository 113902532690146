import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { load } from '../../actions/authActions';
import Spinner from '../../components/Spinner/Spinner';
import { getPermissions } from '../../utils/getPermissions';

const isVisible = () => {
  const [hidden, setHidden] = useState(document.hidden);
  const onChange = () => setHidden(document.hidden);

  useEffect(() => {
    window.addEventListener('visibilitychange', onChange);

    return () => window.removeEventListener('visibilitychange', onChange);
  });

  return !hidden;
};

export const Auth = props => {
  const { auth, children, history, location } = props;
  const { user } = auth;
  const { pathname, search } = location.state || {};
  const userid = user && user.userid;
  const eulaAccepted = user && user.user.eula_accepted;
  const path = location.pathname.toLowerCase();
  const referrer = pathname + search || location.search.split('?redirect=')[1];
  const externalPaths = ['player'];
  const isExternal = referrer && externalPaths.some(url => referrer.toLowerCase().startsWith(url));
  const [authed, setAuthed] = useState(false);
  const [lastUser, setLastUser] = useState(0);
  const p = getPermissions(auth);

  const redirect = () => {
    if (!eulaAccepted) {
      // Do nothing
    } else if (user.user.promptPasswordChange) {
      history.replace('/user');
    } else if (isExternal) {
      window.location.replace(referrer);
    } else if (referrer) {
      history.replace(referrer);
    } else if (p.landingpage) {
      history.replace('/home');
    } else if (p.contentViewer) {
      history.replace('/content');
    } else if (p.deviceViewer) {
      history.replace('/devices');
    } else {
      history.replace('/user');
    }
  };

  useEffect(() => {
    // user logged in, waiting for redirect
    if (userid && ['/', '/login'].includes(path)) {
      redirect();
    }

    // user logged out, prevent rendering, redirect to home page
    if (path === '/logout') {
      setAuthed(false);
      window.location.replace('/');
    }
  }, [userid, location]);

  // re-authenticate when tab becomes visible (user might logged out or new user logged in in a different tab)
  useEffect(() => {
    // new user, reset the previous user's last page referrer
    if (lastUser && lastUser !== userid) {
      setAuthed(false);
      history.push('/');
    }

    setLastUser(userid);
    props.load().finally(() => setAuthed(true));
  }, [userid, isVisible()]);

  if (!authed) {
    return <Spinner loading />;
  }

  if ((!user || (user && !user.user.eula_accepted)) && path !== '/login') {
    return <Redirect to={{ pathname: '/login', state: location }} />;
  }

  return children;
};

Auth.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  load: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});
const mapDispatchToProps = {
  load,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
