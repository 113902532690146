import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  FormGroup,
  InputGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Button,
  Grid,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  Checkbox,
} from 'react-bootstrap';
import clone from 'clone';
import passwordPolicy from '../../../common/passwordPolicy';
import Avatar from '../Avatar/Avatar';

const NO_LOCATION = 'No Location set';

export default class UserForm extends Component {
  getInitialState = () => ({
    showErrors: false,
    errors: {
      name: null,
      email: null,
      firstname: null,
      lastname: null,
      password: null,
      password2: null,
    },
    zxcvbn: null,
    passwordStrength: {},
  });

  state = this.getInitialState();

  componentDidMount() {
    import('zxcvbn').then(module => this.setState({ zxcvbn: module.default }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showErrors } = this.state;

    if (showErrors) {
      this.validate(nextProps);
    }
  }

  onChange = event => {
    const { onEdit } = this.props;

    onEdit({ [event.target.name]: event.target.value });
  };

  onChangeName = event => {
    const { onEdit } = this.props;
    const { value } = event.target;

    if (value.includes(' ')) {
      return;
    }

    onEdit({ name: value });
  };

  onChangePassword = event => {
    const {
      user: { email, firstname, lastname, name },
      onEdit,
    } = this.props;
    const { zxcvbn } = this.state;
    const password = event.target.value;

    if (!zxcvbn) {
      return;
    }

    const passwordStrength = zxcvbn(password, [email, firstname, lastname, name]);

    this.setState({ passwordStrength }, () => onEdit({ password }));
  };

  onChangeRole = event => {
    const { onEdit } = this.props;

    onEdit({
      role_id: +event.target.value,
      role: event.target.options[event.target.selectedIndex].text,
    });
  };

  onChangeChecked = event => {
    const { onEdit } = this.props;

    onEdit({ [event.target.name]: Number(event.target.checked) });
  };

  getPasswordStrengthEstimator = () => {
    const {
      user: { password },
    } = this.props;
    const {
      passwordStrength: { score, feedback, guesses_log10, sequence },
    } = this.state;
    const { requiredLength, requiredScore } = passwordPolicy;

    if (!password) {
      return null;
    }

    const tip = feedback?.warning || '';
    const scoreText = ['high-risk', 'weak', 'okay', 'good', 'strong'][score];
    const isLong = password.length >= requiredLength;
    const isStrong = score >= requiredScore;
    const counterText =
      Math.min(password.length, requiredLength) + (password.length > requiredLength ? '+' : '');
    const counterClass = `badge badge-${isLong ? 'success' : 'danger'}`;
    const scoreClass = `badge badge-${isStrong ? 'success' : 'danger'}`;
    const patterns = Array.from(new Set(sequence?.map(item => item.pattern?.replace(/_/g, ' '))));
    const dictionaries = Array.from(
      new Set(sequence?.map(item => item.dictionary_name?.replace(/_/g, ' ')).filter(Boolean)),
    );

    return (
      <div>
        <span data-test="password-strength-feedback" className="password-strength-feedback">
          {tip}
        </span>
        <div className="password-strength-bar">
          <div className={`score score-${score}`} />
          <div className="stripes" />
        </div>
        <div className="password-score">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-password-score">
                <div className="text-left">
                  <span className="text-light">Estimated guesses, log10:</span>{' '}
                  {guesses_log10?.toFixed(2)}
                  <br />
                  <span className="text-light">Patterns:</span> {patterns.join(', ') || 'N/A'}
                  <br />
                  <span className="text-light">Dictionaries:</span>{' '}
                  {dictionaries.join(', ') || 'N/A'}
                </div>
              </Tooltip>
            }
          >
            <span data-test="password-score" className={scoreClass}>
              {scoreText}
            </span>
          </OverlayTrigger>
          <span data-test="password-length-counter" className={counterClass}>
            {counterText}
          </span>
        </div>
      </div>
    );
  };

  getRoleOptions = () => {
    const {
      auth: {
        user: {
          user: { role, role_id },
        },
      },
      path,
      roles,
    } = this.props;

    if (path === 'user') {
      return <option value={role_id}>{role}</option>;
    }

    if (!roles || !roles.length) {
      return null;
    }

    const options = roles.map(item => (
      <option key={item.role_id} value={item.role_id}>
        {item.name}
      </option>
    ));

    options.unshift(
      <option key={-1} value={-1} disabled>
        Select a role for this user
      </option>,
    );

    return options;
  };

  getUserLocation = () => {
    const { locations, user, userLocation } = this.props;
    const location_id = user.location_id;
    const location = locations.find(item => +item.id === +location_id);

    if (!locations.length && userLocation) {
      return userLocation;
    }

    return location ? `${location.parent}${location.name}/` : NO_LOCATION;
  };

  getButtons = () => {
    const { path, onCancel } = this.props;
    const add = (
      <Button key="1" data-test="button-add" bsStyle="success" type="submit">
        Add User
      </Button>
    );
    const update = (
      <Button key="2" data-test="button-update" bsStyle="success" type="submit">
        Update {path === 'user' ? 'Account' : 'User'}
      </Button>
    );
    const cancel = (
      <Button key="3" data-test="button-cancel" onClick={onCancel}>
        Cancel
      </Button>
    );

    return path === 'new' ? [cancel, add] : [cancel, update];
  };

  validate = nextProps => {
    const props = nextProps || this.props;
    const { user_id, name, email, firstname, lastname, password, password2, role } = props.user;
    const {
      auth: {
        user: {
          permissions: { Admin },
        },
      },
    } = props;
    const {
      passwordStrength: { score },
    } = this.state;
    const { requiredLength, requiredScore } = passwordPolicy;
    const errors = clone(this.getInitialState().errors);
    const tips = {
      required: 'This field cannot be empty.',
      invalid: 'Invalid data.',
      unique: 'This is already in use.',
      match: 'Passwords should match.',
      short: 'Password is short.',
      weak: 'Password is weak.',
    };

    // Only admins can edit name/email - only admins get info to check emails unique
    if (Admin) {
      // name
      if (!name.trim()) {
        errors.name = tips.required;
      } else if (!this.isUnique(props, 'name')) {
        errors.name = tips.unique;
      }

      // email
      if (!email.trim()) {
        errors.email = tips.required;
      } else if (!this.isEmail(email)) {
        errors.email = tips.invalid;
      } else if (!this.isUnique(props, 'email')) {
        errors.email = tips.unique;
      }
    }

    // first and lastname
    if (!firstname.trim()) {
      errors.firstname = tips.required;
    }

    if (!lastname.trim()) {
      errors.lastname = tips.required;
    }

    // password
    if (password.length) {
      if (password.length < requiredLength) {
        errors.password = tips.short;
      } else if (score < requiredScore) {
        errors.password = tips.weak;
      }
    }

    if (user_id < 0) {
      if (!password) {
        errors.password = tips.required;
      }

      if (!password2) {
        errors.password2 = tips.required;
      }
    }

    if (password !== password2) {
      errors.password2 = tips.match;
    }

    // role
    if (!role) {
      errors.role = tips.required;
    }

    // show the errors
    this.setState({ showErrors: true, errors });

    // return false on any error
    return !Object.keys(errors).find(key => errors[key]);
  };

  submit = event => {
    const { path, onUpdateMe, onUpdate, onAdd } = this.props;

    event.preventDefault();

    if (!this.validate()) {
      return;
    }

    switch (path) {
      case 'user':
        onUpdateMe();
        break;
      case ':id':
        onUpdate();
        break;
      default:
        onAdd();
    }
  };

  isUnique = (props, type) => {
    const { users, user } = props;
    const newData = user[type].toLowerCase().trim();

    return !users.some(item => {
      const oldData = item[type].toLowerCase().trim();

      return +item.user_id !== +user.user_id && newData === oldData;
    });
  };

  isEmail = email =>
    email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    );

  isPermissionSet = permission => {
    const { roles, user } = this.props;
    const role = roles.find(item => +item.role_id === +user.role_id);

    return role ? role.perm.includes(permission) : false;
  };

  isCommanderRole = () => this.isPermissionSet('Commander');

  isDeployerRole = () => this.isPermissionSet('Deployer');

  isCurrentUserOrNotAdmin = () => {
    const {
      auth: {
        user: {
          permissions: { Admin },
        },
      },
      path,
    } = this.props;

    if (path === 'user' || !Admin) {
      return true;
    }

    return false;
  };

  shouldShowMfa = () => {
    const { auth, path } = this.props;
    const isMfaEnabledForClient = auth.user.client.mfa > 0;
    const isNotNewUser = path !== 'new';

    return isMfaEnabledForClient && isNotNewUser;
  };

  errorClass = error => (error ? 'error' : null);

  passwordPlaceholder = user => {
    const { zxcvbn } = this.state;
    let placeholder = '';

    if (!zxcvbn) {
      placeholder = 'Loading password strength estimator, please wait...';
    } else if (user.user_id > 0) {
      placeholder = 'To change the password, type a new one, otherwise leave this blank.';
    }

    return placeholder;
  };

  render() {
    const {
      auth: {
        user: {
          userid,
          permissions: { Admin },
          user: { isSSO },
        },
      },
      user,
      onClickUserLocation,
      onClickAvatar,
      onClickMfa,
    } = this.props;
    const { errors, zxcvbn } = this.state;
    const {
      user_id,
      role_id,
      name,
      email,
      firstname,
      lastname,
      password,
      password2,
      promptPasswordChange,
    } = user;
    const owner = userid === user_id;

    return (
      <form onSubmit={this.submit}>
        <fieldset disabled={Boolean(isSSO)}>
          <Grid fluid>
            <Row>
              <Col md={6}>
                <FormGroup validationState={this.errorClass(errors.name)}>
                  <ControlLabel>Username</ControlLabel>
                  <FormControl
                    data-test="input-name"
                    name="name"
                    autoComplete="no"
                    value={name}
                    onChange={this.onChangeName}
                    disabled={!Admin}
                  />
                  <HelpBlock>{errors.name}</HelpBlock>
                </FormGroup>
                <FormGroup validationState={this.errorClass(errors.email)}>
                  <ControlLabel>Email</ControlLabel>
                  <FormControl
                    data-test="input-email"
                    name="email"
                    type="email"
                    onChange={this.onChange}
                    value={email}
                    disabled={!Admin}
                  />
                  <HelpBlock>{errors.email}</HelpBlock>
                </FormGroup>
                <FormGroup validationState={this.errorClass(errors.firstname)}>
                  <ControlLabel>First Name</ControlLabel>
                  <FormControl
                    data-test="input-firstname"
                    name="firstname"
                    value={firstname}
                    onChange={this.onChange}
                  />
                  <HelpBlock>{errors.firstname}</HelpBlock>
                </FormGroup>
                <FormGroup validationState={this.errorClass(errors.lastname)}>
                  <ControlLabel>Last Name</ControlLabel>
                  <FormControl
                    data-test="input-lastname"
                    name="lastname"
                    value={lastname}
                    onChange={this.onChange}
                  />
                  <HelpBlock>{errors.lastname}</HelpBlock>
                </FormGroup>
                <Alert bsStyle="warning" className="text-small">
                  To conform with our Strong Password Policy, you are required to use a sufficiently
                  strong and at least 8 characters long password.
                </Alert>
                <FormGroup validationState={this.errorClass(errors.password)} className="rel">
                  <ControlLabel>Password</ControlLabel>
                  <FormControl
                    data-test="input-password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    placeholder={this.passwordPlaceholder(user)}
                    disabled={!zxcvbn}
                    value={password}
                    onChange={this.onChangePassword}
                  />
                  <HelpBlock data-test="password-errors">{errors.password}</HelpBlock>
                  {this.getPasswordStrengthEstimator()}
                </FormGroup>
                <FormGroup validationState={this.errorClass(errors.password2)}>
                  <ControlLabel>Repeat Password</ControlLabel>
                  <FormControl
                    data-test="input-repeat-password"
                    name="password2"
                    type="password"
                    autoComplete="new-password"
                    value={password2}
                    onChange={this.onChange}
                  />
                  <HelpBlock>{errors.password2}</HelpBlock>
                </FormGroup>
                {!this.isCurrentUserOrNotAdmin() && (
                  <FormGroup>
                    <Checkbox
                      data-test="checkbox-password-change"
                      name="promptPasswordChange"
                      checked={promptPasswordChange}
                      onChange={this.onChangeChecked}
                    >
                      Prompt user to change password on next login
                    </Checkbox>
                  </FormGroup>
                )}
                {this.shouldShowMfa() && (
                  <FormGroup>
                    <ControlLabel>Two-factor authentication</ControlLabel>
                    <InputGroup>
                      <FormControl
                        data-test="input-mfa"
                        value={user.mfa_verified ? 'enabled' : 'disabled'}
                        disabled
                      />
                      <InputGroup.Button>
                        <Button data-test="button-mfa-edit" onClick={onClickMfa}>
                          Edit
                        </Button>
                      </InputGroup.Button>
                    </InputGroup>
                  </FormGroup>
                )}
              </Col>
              <Col md={6}>
                <FormGroup validationState={this.errorClass(errors.role)}>
                  <ControlLabel>Role</ControlLabel>
                  <FormControl
                    data-test="select-role"
                    name="role"
                    componentClass="select"
                    value={role_id}
                    onChange={this.onChangeRole}
                    disabled={owner}
                  >
                    {this.getRoleOptions()}
                  </FormControl>
                  <HelpBlock>{errors.role}</HelpBlock>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>User Location</ControlLabel>
                  <FormControl
                    data-test="input-location"
                    name="location_id"
                    value={this.getUserLocation()}
                    onClick={onClickUserLocation}
                    disabled={!Admin}
                    readOnly
                  />
                </FormGroup>
                <ControlLabel>Avatar</ControlLabel>
                <div className="avatar-holder">
                  <Avatar size={200} user={user} />
                  {owner && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="upload-avatar-tooltip-helper">Change Profile Picture</Tooltip>
                      }
                    >
                      <span
                        data-test="upload-hover"
                        className="upload-hover"
                        onClick={onClickAvatar}
                      >
                        <i className="material-icons">photo_camera</i>
                      </span>
                    </OverlayTrigger>
                  )}
                </div>
              </Col>
            </Row>
          </Grid>
          <div className="form-buttons">{this.getButtons()}</div>
        </fieldset>
      </form>
    );
  }
}

UserForm.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object,
  users: PropTypes.array,
  roles: PropTypes.array,
  locations: PropTypes.array,
  path: PropTypes.string.isRequired,
  onClickUserLocation: PropTypes.func.isRequired,
  onClickAvatar: PropTypes.func.isRequired,
  onClickMfa: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateMe: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  userLocation: PropTypes.string.isRequired,
};
