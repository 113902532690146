import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Cookies from 'universal-cookie';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormGroup, FormControl, Grid, Row, Col, Button, Checkbox, Alert } from 'react-bootstrap';
import { load, logoutUser, nextPath } from '../../../actions/authActions';
import { mfaLogin } from '../../../actions/mfaActions';
import { updateMe } from '../../../actions/userActions';
import config from '../../../config';
import EulaModal from '../../../components/Eula/EulaModal';
import MfaModal from '../../../components/Mfa/MfaModal';
import PasswordPrompt from '../../../components/Prompt/PasswordPrompt';
import { ssoLogin, ssoFetchIdps } from '../../../actions/ssoActions';

const cookies = new Cookies();

export class LoginPage extends Component {
  state = {
    user: {},
    userLogin: {
      clientname: cookies.get('clientname'),
      username: cookies.get('username'),
      remember: Boolean(cookies.get('remember')),
      password: '',
    },
    safeName: null,
    showWelcome: false,
    showWelcome_Greet: false,
    showWelcome_Success: false,
    loginPending: false,
    showMfa: false,
    showEula: false,
    showPass: false,
    seenEula: false,
    clientMfa: null,
    mfa_verified: null,
    isSAML: false,
  };

  constructor(props) {
    super(props);
    this.fetchIdps().then(idps => {
      this.setState({ idps });
    });
  }

  showEulaIfNotAccepted = () => {
    if (this.state.loginPending) {
      this.setState({ loginPending: false });
    }

    const { user } = this.props;
    const { seenEula } = this.state;
    const { showWelcome } = this.state;

    if (!showWelcome && !seenEula && user && user.user?.sso_session_id) {
      const { firstname } = user.user;

      this.setState({ showWelcome_Greet: true, showWelcome: true, firstname, user: user.user });

      // Display EULA after Welcome has been displayed
      setTimeout(() => {
        this.setState({ seenEula: true });
        this.step3acceptEula();
      }, 5000);
    }
  };

  componentDidMount = () => {
    // Show new user greeting and EULA form if SSO user hasn't yet accepted
    this.showEulaIfNotAccepted();
  };

  fetchIdps = async () => {
    try {
      const { idps } = await ssoFetchIdps();

      return idps;
    } catch (e) {
      console.log('SSO IdP Retrieval Error: Admin, please diagnose issue:', e);
    }
  };

  // ===========================================================================
  // SSO Related functions - start
  // ===========================================================================

  // Button click: SSO integration option
  step1ssoLogin = async event => {
    event.preventDefault();
    event.stopPropagation();

    const { loginPending } = this.state;

    if (loginPending) {
      return;
    }

    const button = event.target.closest('button');
    const idp = parseInt(button.getAttribute('idp-id'), 10);

    this.setState({
      idp,
      isSAML: true,
      loginError: false,
    });
  };

  // Button click: Contiue to IdP SSO sign-in page
  step2ssoLogin = async event => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ loginError: false, loginErrorSSO: false, loginPending: true });
    const { idp } = this.state;
    const { location } = this.props;
    let redirectPath = null;

    if (location?.state) {
      const {
        state: { hash, pathname, search },
      } = location;

      redirectPath = `${pathname}${hash}${search}`;
    }

    const { clientname: client } = this.getCredentials();

    try {
      const res = await ssoLogin(client, idp, redirectPath);
      const { url } = res;

      window.location.href = url;
    } catch (e) {
      return this.showErrorSSO();
    }
  };

  // Button click: Back to standard login
  step2ssoCancel = async event => {
    event.preventDefault();
    event.stopPropagation();

    const { idp, ...state } = this.state;

    this.setState({ ...state, loginErrorSSO: false, isSAML: false });
  };

  // ===========================================================================
  // SSO Related functions - end
  // ===========================================================================

  onChange = event => {
    const { userLogin } = this.state;
    const { name, value, checked } = event.target;

    userLogin[name] = name === 'remember' ? checked : value;
    this.setState({ userLogin });
  };

  onSuccessMfa = user => this.setState({ user }, this.step3acceptEula);

  showError = () => this.setState({ loginPending: false, loginError: true });

  showErrorSSO = () => this.setState({ loginPending: false, loginErrorSSO: true });

  hideMfa = () => this.setState({ showMfa: false });

  getCredentials = () => {
    const { clientname, username, password } = this.state.userLogin;

    return { clientname, username, password };
  };

  eulaDisagree = () => {
    logoutUser();
    this.setState({ showEula: false, showWelcome: false });
  };

  eulaAgree = async () => {
    const { user } = this.state;
    const isSSO = Boolean(user.sso_session_id);

    user.eula_accepted = config.app.eulaVersion;
    this.setState({ showEula: false });

    try {
      await updateMe(user);
    } catch (e) {
      this.setState({ loginError: e.error });

      return this.showError();
    }

    if (isSSO) {
      this.setState({ showWelcome_Greet: false, showWelcome_Success: true });
      setTimeout(() => {
        window.location.reload();
      }, 5000);

      return;
    }

    if (!user.promptPasswordChange) {
      return this.step5setCookies();
    }

    this.step4changePass();
  };

  step1basicLogin = async event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ loginError: false, loginPending: true });

    let res;

    try {
      res = await mfaLogin(this.getCredentials());
    } catch (e) {
      return this.showError();
    }

    this.setState({ loginPending: false });
    const { user, clientMfa, mfa_verified } = res;

    // user logged in using basic auth
    if (user) {
      this.setState({ user });

      return this.step3acceptEula();
    }

    // user must login using 2FA
    this.setState({ clientMfa, mfa_verified });
    this.step2tokenLogin();
  };

  step2tokenLogin = () => this.setState({ showMfa: true, showEula: false, showPass: false });

  step3acceptEula = () => {
    const { eula_accepted, sso_session_id: isSSO } = this.state.user;
    const { eulaVersion } = config.app;

    // user must accept EULA
    if (!eula_accepted || eula_accepted < eulaVersion) {
      return this.setState({ showMfa: false, showEula: true, showPass: false });
    }

    // If this is an SSO login then skip password change
    if (!isSSO) {
      this.step4changePass();
    }
  };

  step4changePass = () => {
    const { promptPasswordChange } = this.state.user;

    if (promptPasswordChange) {
      return this.setState({ showMfa: false, showEula: false, showPass: true });
    }

    this.step5setCookies();
  };

  step5setCookies = () => {
    const { clientname, username, remember } = this.state.userLogin;
    const options = { secure: true, maxAge: 31536000 }; // 1 year

    if (remember) {
      cookies.set('clientname', clientname, options);
      cookies.set('username', username, options);
      cookies.set('remember', remember, options);
    } else {
      cookies.remove('clientname');
      cookies.remove('username');
      cookies.remove('remember');
    }

    this.step6redirect();
  };

  step6redirect = this.props.load;

  render() {
    const {
      userLogin: { clientname, username, password, remember },
      firstname,
      loginError,
      loginErrorSSO,
      loginPending,
      showWelcome,
      showWelcome_Greet,
      showWelcome_Success,
      showMfa,
      showEula,
      showPass,
      mfa_verified,
      clientMfa,
      isSAML,
      idps,
      idp: selectedIdp,
    } = this.state;
    const isIdpEnabled = Array.isArray(idps) && idps.length > 0;

    return (
      <div className="login-page">
        <Helmet title="Login" />
        {showWelcome && (
          <div className="sso-welcome">
            {showWelcome_Greet && (
              <div className="greet">
                <h1>Hi {firstname}, Welcome to SignStix!</h1>
              </div>
            )}
            {showWelcome_Success && (
              <div className="welcome">
                <h1>Thank you, {firstname}!</h1>
              </div>
            )}
          </div>
        )}
        <div className="login-dialog">
          <img
            className="logo"
            data-test="logo"
            alt="logo"
            src="/assets/images/logo/signstix_landscape_orange.svg"
          />
          <form className="login-form form-inline" method="POST">
            {loginErrorSSO && (
              <Alert data-test="warning" bsStyle="danger">
                <b>Login failed</b>
                <p>Please check the following:</p>
                <ul>
                  <li>Your client name is correct</li>
                  <li>Your company supports SSO (single sign-on)</li>
                </ul>
              </Alert>
            )}
            {loginError && (
              <Alert data-test="warning" bsStyle="danger">
                <b>Login failed</b>
                <p>Please check your client name, username and password and try again</p>
              </Alert>
            )}
            <Grid fluid style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <Row>
                <Col md={12}>
                  <FormGroup controlId="login-clientname">
                    <FormControl
                      autoFocus
                      data-test="input-clientname"
                      type="text"
                      name="clientname"
                      placeholder="Client Name"
                      onChange={this.onChange}
                      defaultValue={clientname}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {!isSAML && (
                <>
                  <Row>
                    <Col md={12}>
                      <FormGroup controlId="login-username">
                        <FormControl
                          data-test="input-username"
                          type="text"
                          name="username"
                          placeholder="Username"
                          onChange={this.onChange}
                          defaultValue={username}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup controlId="login-password">
                        <FormControl
                          data-test="input-password"
                          type="password"
                          name="password"
                          placeholder="Password"
                          onChange={this.onChange}
                          defaultValue={password}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup controlId="login-remember" className="checkbox">
                        <Checkbox
                          data-test="checkbox-remember"
                          inline
                          name="remember"
                          checked={remember}
                          onChange={this.onChange}
                        >
                          <span
                            style={{
                              fontSize: '12px',
                              position: 'relative',
                              top: '-2px',
                              left: '-4px',
                              userSelect: 'none',
                            }}
                          >
                            Remember me
                          </span>
                        </Checkbox>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button
                        disabled={Boolean(loginPending)}
                        data-test="button-submit"
                        type="submit"
                        bsStyle="primary"
                        bsSize="large"
                        block
                        onClick={this.step1basicLogin}
                      >
                        {loginPending ? 'Please wait...' : 'Log In'}
                      </Button>
                    </Col>
                  </Row>
                  {isIdpEnabled && (
                    <>
                      <hr style={{ marginBottom: '10px' }} />
                      {idps.map(idp => {
                        return (
                          <Row key={idp.id}>
                            <Col md={12}>
                              <div className="form-group" style={{ marginTop: '10px' }}>
                                <Button
                                  idp-id={idp.id}
                                  data-test={`button-${idp.tag}`}
                                  type="submit"
                                  bsStyle="info"
                                  block
                                  onClick={this.step1ssoLogin}
                                  style={{ textAlign: 'left' }}
                                >
                                  <img
                                    alt={idp.tag}
                                    height={18}
                                    width={18}
                                    className="logo-integration pull-left"
                                    src={`/assets/images/misc/integrations/${idp.tag}/logo.svg`}
                                  />
                                  <span
                                    style={{
                                      marginLeft: '10px',
                                      paddingLeft: '10px',
                                      borderLeft: '1px solid #fff',
                                    }}
                                  >
                                    Log In with {idp.name}
                                  </span>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  )}
                </>
              )}
              {isSAML && (
                <>
                  <Row style={{ marginTop: '20px' }}>
                    <Col md={12}>
                      <Button
                        disabled={Boolean(loginPending)}
                        data-test="button-entra"
                        type="submit"
                        bsStyle="primary"
                        bsSize="large"
                        block
                        onClick={this.step2ssoLogin}
                      >
                        <div style={{ marginTop: '-5px' }}>
                          <i
                            style={{ marginRight: '5px' }}
                            className="material-icons inline color-white"
                          >
                            input
                          </i>
                          <span>
                            {loginPending
                              ? 'Please wait...'
                              : `Continue to ${idps.find(idp => idp.id === selectedIdp).name}`}
                          </span>
                        </div>
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12}>
                      <Button
                        data-test="button-entra"
                        type="submit"
                        bsStyle="info"
                        bsSize="large"
                        block
                        onClick={this.step2ssoCancel}
                      >
                        <div style={{ marginTop: '-5px' }}>
                          <i
                            style={{ marginRight: '5px' }}
                            className="material-icons inline color-white"
                          >
                            arrow_back
                          </i>
                          <span>Back to Standard Login</span>
                        </div>
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Grid>
          </form>
        </div>
        <MfaModal
          show={showMfa}
          credentials={this.getCredentials()}
          clientMfa={clientMfa}
          verified={mfa_verified}
          onHide={this.hideMfa}
          onSuccess={this.onSuccessMfa}
        />
        <EulaModal
          show={showEula}
          onHide={this.eulaDisagree}
          onSuccess={this.eulaAgree}
          version={config.app.eulaVersion}
        />
        <PasswordPrompt show={showPass} onSuccess={this.step5setCookies} />
      </div>
    );
  }
}

LoginPage.propTypes = {
  user: PropTypes.object,
  nextPath: PropTypes.func,
  load: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      nextPath,
      load,
    },
    dispatch,
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
